import React from 'react';
import GetInTouch from '../components/ContactUs/GetInTouch';
import ContactForm from '../components/ContactUs/ContactForm';
import QuestionSection from '../components/ContactUs/QuestionSection';

function ContactPage() {
  return (
    <div className="flex overflow-hidden flex-col bg-white">
      <main className="mt-20 w-full max-w-[1292px] max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-5 max-md:flex-col">
          <div className="flex flex-col w-[57%] max-md:ml-0 max-md:w-full">
            <GetInTouch />
          </div>
          <div className="flex flex-col ml-5 w-[43%] max-md:ml-0 max-md:w-full">
            <ContactForm />
          </div>
        </div>
      </main>
      <QuestionSection />
    </div>
  );
}

export default ContactPage;
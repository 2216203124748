import React from 'react';
import TeamMember from './TeamMember';

const teamMembers = [
  { name: 'Mr Muleta Tembo', position: 'Executive Director' },
  { name: 'Mr Djazi Eugene Niapa', position: 'Senior Trade Executive' },
  { name: 'Ms Lauzi Isabel Tembo', position: 'Head of Administration and Research' },
  { name: 'Mr Alex Likukela Musonda', position: 'Junior Regional Trade Executive' }
];

const OurTeam = () => {
  return (
    <section className="flex overflow-hidden flex-col justify-center items-center self-stretch py-20 pr-36 pl-40 mt-4 ml-3.5 bg-white max-md:px-5 max-md:pt-24 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col items-center max-w-full w-[1110px]">
        <div className="flex flex-col items-center max-w-full text-center w-[640px]">
          <h2 className="text-5xl font-semibold tracking-tighter leading-none text-slate-900 max-md:text-4xl">
            Our Team
          </h2>
          <p className="mt-5 text-sm leading-4 text-slate-600 max-md:max-w-full">
            It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
          </p>
        </div>
        <div className="flex flex-col mt-16 w-full max-w-[1110px] max-md:mt-10 max-md:max-w-full">
          <div className="flex flex-wrap gap-5 items-start max-md:max-w-full">
            {teamMembers.map((member, index) => (
              <TeamMember key={index} name={member.name} position={member.position} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurTeam;
import React from 'react';

const CompanyHistory = () => {
  return (
    <section className="flex overflow-hidden flex-col justify-center items-center self-stretch px-16 py-20 mt-32 w-full bg-white max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-wrap gap-10 items-start max-md:max-w-full">
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/22548ae7913a89546d42ca0590d637479420cabf6f68f687d388d59f6ff379b6?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&" alt="Company history illustration" className="object-contain aspect-[1.07] min-w-[240px] w-[600px] max-md:max-w-full" />
        <div className="flex flex-col min-w-[240px] w-[410px]">
          <div className="flex flex-col max-w-full w-[410px]">
            <h2 className="flex flex-col w-full text-4xl font-bold tracking-tighter leading-none text-slate-900">
              <span className="w-full">Company History</span>
            </h2>
            <p className="mt-8 text-lg leading-6 text-slate-500">
              I-PLUS was established in 2012 as a Zambian-based company focused on international trade. Since its inception, I-PLUS has rapidly grown and expanded its services to cater to the evolving needs of global businesses. The company's commitment to excellence and customer satisfaction has enabled it to build strong partnerships and establish a solid reputation in the industry.
            </p>
          </div>
          <div className="flex gap-3 items-start self-start mt-16 max-md:mt-10">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/c3734c1f15f7db2691908c1606a0e98b6e41661815c7c8431111bc13dad0fe6a?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&" alt="Social media icon 1" className="object-contain shrink-0 w-9 aspect-square" />
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/bfc10ec1eb09b3ee49c22b8295d6b277d162a67b2e0861dd8f62e60ebe16f6e7?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&" alt="Social media icon 2" className="object-contain shrink-0 w-9 aspect-square" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default CompanyHistory;
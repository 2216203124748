import React from 'react';

function ContactItem({ icon, title, details, iconClass }) {
  const vibrateClass = title === "Call Us On" ? "vibrate" : "";
  return (
    <div className="flex flex-col w-[37%] max-md:ml-0 max-md:w-full">
      <div className="flex flex-col grow items-center text-black max-md:mt-10">
        <img loading="lazy" src={icon} alt="" className={`object-contain aspect-square ${iconClass}`} />
        <h2 style={{ fontFamily: "roboto, sans-serif" }} className="mt-4 text-[20px] font-semibold leading-28">{title}</h2>
        <div style={{ fontFamily: "roboto, sans-serif" }} className="self-stretch mt-5 text-[16px] leading-normal text-center">
          {details.map((detail, index) => (
            <React.Fragment key={index}>
              {detail}
              {index < details.length - 1 && <br />}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ContactItem;
import React from 'react';

const MissionSection = () => {
  return (
    <section className="mt-72 w-full max-w-[1207px] mx-auto text-center max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 justify-center items-center max-md:flex-col">
        <div className="flex flex-col w-[62%] max-md:w-full">
          <div className="flex flex-col items-start text-left font-medium text-black max-md:text-center max-md:items-center">
            <h2 className="text-xl text-zinc-500">OUR MISSION</h2>
            <h3 className="self-stretch mt-14 text-6xl max-md:mt-10 max-md:text-4xl">
              Connecting Businesses, Bridging Markets
            </h3>
            <p className="mt-11 text-base font-light max-md:mt-10">
              "To connect businesses across borders, bridge cultural and market gaps, and facilitate seamless international trade."
            </p>
          </div>
        </div>
        <div className="flex flex-col w-[38%] max-md:w-full">
          <img 
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7db496f5676c52fe3882ffae27887231c1dad5a1b4beb948ced84937e11c49dd?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&"
            alt="Mission illustration"
            className="object-contain grow mt-9 w-full aspect-[0.87] max-md:mt-10"
          />
        </div>
      </div>
    </section>
  );
};

export default MissionSection;

import React from 'react';
import serviceImg from '../../assets/img/serviceImg.png';

const Hero = () => {
  return (
    <section className="mt-24 text-center max-md:mt-10 max-md:max-w-full">
      <h1 className="text-6xl text-black max-md:max-w-full max-md:text-4xl">
        Innovation Meets Expertise in Our Range Of Services
      </h1>
      <img 
        loading="lazy" 
        src={serviceImg} 
        alt="Innovative services illustration" 
        className="object-contain mt-11 w-full aspect-[2.57] max-md:mt-10 max-md:max-w-full" 
      />
    </section>
  );
};

export default Hero;
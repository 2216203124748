import React from 'react';

const ValuesSection = () => {
  return (
    <section className="px-px mt-28 w-full max-w-[1183px] mx-auto text-center max-md:mt-10 max-md:max-w-full">
      <div className="flex gap-5 justify-center items-center max-md:flex-col">
        <div className="flex flex-col w-[61%] max-md:w-full">
          <div className="grow max-md:mt-10 max-md:max-w-full">
            <div className="flex gap-5 max-md:flex-col">
              <div className="flex flex-col w-6/12 max-md:w-full">
                <img 
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a0e6e9c5cdf53929faadfe1f029b50d645e3c546ab3067f23d19e8855495a326?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&"
                  alt="Company value illustration 1"
                  className="object-contain z-10 w-full aspect-[0.85]"
                />
              </div>
              <div className="flex flex-col w-6/12 max-md:w-full">
                <img 
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/27948c447a226633ef6ab8e557056a7b1bfd2a8bdd48414b00d9b5f17b39ed4f?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&"
                  alt="Company value illustration 2"
                  className="object-contain mt-40 w-full aspect-[0.85] max-md:mt-10"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-[39%] max-md:w-full">
          <div className="flex flex-col items-start mt-14 font-semibold text-black text-left max-md:items-center max-md:text-center max-md:mt-10">
            <h2 className="text-xl text-zinc-500">OUR VALUES</h2>
            <h3 className="mt-9 text-6xl max-md:text-4xl">
              "Inspired <br /> Innovative and Involved"
            </h3>
            <p className="mt-14 text-base font-light max-md:mt-10">
              "Inspired by the potential of international trade, I-PLUS offers innovative solutions and is deeply involved in helping businesses thrive on a global scale."
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ValuesSection;

import React from 'react';
import Testimonial from './Testimonial';

const testimonials = [
  {
    name: 'Trenton Duffy',
    role: 'Graduate',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/f7fb2ccb48444165c8530abee51200af4d5fdf9ab36000815edb341d93172a67?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&',
  },
  {
    name: 'Nadia Jennings',
    role: 'Student',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/9e670aa76a04db0890b1d3d6777bddd7416155380188d1eb61891c0a9917d37c?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&',
  },
  {
    name: 'Blaine Ramirez',
    role: 'Parent',
    imageSrc:
      'https://cdn.builder.io/api/v1/image/assets/TEMP/5974b8a7ffc3e6a8a3139052c22f43f1e1c68db3aa3bdacf5d941af16e1547fe?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&',
  },
];

const TestimonialSection = () => {
  return (
    <section className="mt-56 pb-20 text-center max-md:mt-10 max-md:max-w-full">
      <h2 className="text-3xl font-semibold tracking-tighter leading-none text-teal-500 whitespace-nowrap max-md:max-w-full">
        TESTIMONIALS
      </h2>
      <p className="mt-16 text-4xl font-extralight leading-[65px] text-slate-900 max-md:mt-10 max-md:max-w-full">
        "I-PLUS was the key to my business success. Their expert guidance and tailored solutions helped me navigate the complexities of international trade and achieve my goals."
      </p>
      {/* Center the testimonials container */}
      <div className="mt-9 mx-auto max-w-[650px] w-full">
        <div className="flex gap-5 justify-center max-md:flex-col">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="flex flex-col w-[33%] max-md:w-full">
              <Testimonial {...testimonial} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;

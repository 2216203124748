import React from 'react';

const Testimonial = ({ name, role, imageSrc }) => {
  return (
    <div className="flex flex-col items-center self-stretch my-auto max-md:mt-10">
      <img loading="lazy" src={imageSrc} alt={`${name}'s profile`} className="object-contain rounded-full aspect-square w-[50px]" />
      <div className="self-stretch mt-4 text-lg font-medium leading-loose text-slate-900">
        {name}
      </div>
      <div className="text-base leading-7 text-gray-700">{role}</div>
    </div>
  );
};

export default Testimonial;
import React from 'react';
import FeatureCard from './FeatureCard';

const features = [
  {
    number: '01',
    title: 'Dedicated Client Service',
    description: 'We prioritize exceeding client expectations, providing personalized attention and going the extra mile.',
    bgColor: 'bg-teal-500'
  },
  {
    number: '02',
    title: 'Global Network & Expertise',
    description: 'We prioritize exceeding client expectations, providing personalized attention and going the extra mile.',
    bgColor: 'bg-slate-400'
  },
  {
    number: '03',
    title: 'Efficiency & Timeliness:',
    description: 'We recognize the importance of time-sensitive operations and strive for seamless execution.',
    bgColor: 'bg-slate-400'
  },
  {
    number: '04',
    title: 'Innovative Approach:',
    description: 'We embrace the latest technologies while upholding traditional business principles for optimal results.',
    bgColor: 'bg-teal-500'
  }
];

function WhatSetsUsApart() {
  return (
    <section className="flex flex-col pt-16 pr-11 mt-12 ml-12">
      <h2 className="z-10 self-start -mt-2 text-5xl text-black max-md:text-4xl">
        What Sets Us Apart
      </h2>
      <div className="pb-px mt-16 -mb-px w-full max-md:mt-10 max-md:max-w-full">
        <div className="flex gap-0 max-md:flex-col">
          {features.slice(0, 2).map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
      <div className="w-full max-md:max-w-full">
        <div className="flex gap-0 max-md:flex-col">
          {features.slice(2).map((feature, index) => (
            <FeatureCard key={index} {...feature} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default WhatSetsUsApart;
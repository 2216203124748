import React from 'react';

function ContactForm() {
  return (
    <form className="flex flex-col px-10 py-14 mx-auto mt-7 w-full text-base text-gray-400 bg-white rounded-[33px] border border-gray-300 shadow-md max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <h2 className="self-start text-5xl font-semibold text-black max-md:text-4xl">
        Get in Touch
      </h2>
      <p className="self-start mt-9 text-black">You can reach us anytime</p>
      <div className="flex gap-9 mt-20 tracking-tight max-md:mt-10 max-md:max-w-full">
        <input
          type="text"
          placeholder="Name"
          className="flex-1 px-3.5 py-3 bg-white rounded-2xl border border-gray-400"
          aria-label="Name"
        />
        <input
          type="text"
          placeholder="Last name"
          className="flex-1 px-3.5 py-3 bg-white rounded-2xl border border-gray-400"
          aria-label="Last name"
        />
      </div>
      <div className="flex flex-col justify-center items-start px-3.5 py-3 mt-8 tracking-tight bg-white rounded-2xl border max-md:pr-5 max-md:max-w-full">
        <div className="flex gap-2 items-start">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/60d20862df6275031a17861b154410226d92ac753807428f8b8315c74263f1bd?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" 
            className="object-contain shrink-0 aspect-square w-[23px]" 
            alt="" 
          />
          <input 
            type="email" 
            placeholder="Your email" 
            className="bg-transparent flex-1" 
            aria-label="Your email" 
          />
        </div>
      </div>
      <div className="flex flex-col justify-center items-start px-3.5 py-3 mt-5 tracking-tight bg-white rounded-2xlmax-md:pr-5 max-md:max-w-full">
        <div className="flex gap-2 items-start">
          <img 
            loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f070ae50b3964944a64f8f00fe90137263e3e6ca03023de375f6ec7889b4b950?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf" 
            className="object-contain shrink-0 aspect-square w-[23px]" 
            alt="" 
          />
          <input 
            type="tel" 
            placeholder="Phone number" 
            className="bg-transparent flex-1" 
            aria-label="Phone number" 
          />
        </div>
      </div>
      <textarea
        placeholder="How can we help?"
        className="px-3.5 pt-3 pb-14 mt-5 tracking-tight bg-white rounded-2xl border border-gray-400 max-md:pr-5 max-md:max-w-full"
        aria-label="How can we help?"
      ></textarea>
      <button type="submit" className="px-16 py-6 mt-6 text-2xl font-semibold leading-none text-center text-white whitespace-nowrap bg-teal-500 rounded-[33px] max-md:px-5 max-md:max-w-full">
        Submit
      </button>
      <p className="self-center mt-5 ml-2.5 text-sm font-semibold leading-6 text-center text-black">
        <span className="text-gray-400">By contacting us, you agree to our </span>
        <span className="">Terms</span>
        <br />
        <span className="">of service </span>
        <span className="text-gray-400">and</span>
        <span className=""> Privacy Policy</span>
      </p>
    </form>
  );
}

export default ContactForm;

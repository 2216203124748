import React from 'react';

const AboutSection = () => {
  return (
    <section className="flex flex-col items-center self-stretch px-20 pt-10 pb-20 mt-14 w-full text-xl text-center text-black bg-teal-500 bg-opacity-30 max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <div className="flex flex-col max-w-full w-[563px]">
        <p className="max-md:max-w-full">
          We provide the best of services to our clients by maximizing the innovation{" "}
        </p>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/da4fb47bf58293fe909389f5f340217f7ac095d610c2161eb571d77f89899730?apiKey=4a2443311a3b46ed8000cc9bfe96a0bf&" alt="Decorative element" className="object-contain self-center mt-16 max-w-full aspect-[4.9] w-[245px] max-md:mt-10" />
      </div>
    </section>
  );
};

export default AboutSection;
import React, { useState } from 'react';
import ServiceCard from './ServiceCard';

const servicesData = [
  {
    title: "Immigration Consultancy",
    description: "With over Ten (10) years of experience, we have become a trusted Immigration Advisory and Consultancy partner for international, corporate as well as individual clients locally, regionally and across the globe",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/0767685efaf1f394f7ec6fe3e148826a1996066502184de7fde745939f20d830?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
  },
  {
    title: "Regional & Global Trade Consultancy",
    description: "We provide trade and linkage support as well as Mandate services for multi sectoral trade projects and programs",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/232e736bc8cfcd1f6bd998987ab09b2bc55462048326813549962ca919784d02?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
  },
  {
    title: "Global Commodity Sourcing",
    description: "Our Commodity Sourcing division has developed a strong database of direct end suppliers as well as supply chain support partners to ease the pressure of a hectic sourcing process",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/0767685efaf1f394f7ec6fe3e148826a1996066502184de7fde745939f20d830?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
  },
  {
    title: "Project Management & Business Advisory",
    description: "We offer guidance on Project Management navigation as well as specific Business Advisory services",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/0767685efaf1f394f7ec6fe3e148826a1996066502184de7fde745939f20d830?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
  },
  {
    title: "Multi-Sector Investment Brokerage",
    description: "Our database of multi-sectoral investment partners helps create linkages and foster business relations with investment and partnership ready business entities",
    imageUrl: "https://cdn.builder.io/api/v1/image/assets/TEMP/0767685efaf1f394f7ec6fe3e148826a1996066502184de7fde745939f20d830?placeholderIfAbsent=true&apiKey=4a2443311a3b46ed8000cc9bfe96a0bf"
  }
];

const Services = () => {
  // State to store the index of the selected card
  const [selectedIndex, setSelectedIndex] = useState(null);

  // Handle click on the card
  const handleCardClick = (index) => {
    setSelectedIndex(index);
  };

  return (
    <section className="flex flex-col px-20 mt-20 w-full max-md:px-5 max-md:mt-10 max-md:max-w-full">
      <h2 className="self-start text-5xl text-black max-md:text-4xl">
        Our Core Services{" "}
      </h2>
      {servicesData.map((service, index) => (
        <ServiceCard
          key={index}
          {...service}
          isSelected={selectedIndex === index} // Pass whether this card is selected
          onClick={() => handleCardClick(index)} // Handle click to select this card
        />
      ))}
    </section>
  );
};

export default Services;

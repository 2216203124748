import React from "react";
import TimelineCard from "./TimelineCard";

const timelineData = [
  {
    year: "2013",
    title:
      "2013: IRIDIUM PLUS is established as a wholly owned Zambian business entity.",
    description:
      "Dedicated to empowering businesses to achieve success in the global market. The company offers a comprehensive suite of services designed to streamline trade operations, from sourcing commodities to navigating complex international transactions.",
  },
  {
    year: "2015",
    title:
      "2015: The company expands its operations to South Africa, marking its first international presence.",
    description:
      "The company establishes a regional office in Johannesburg to better serve its clients in the Southern African region. This strategic move allows IRIDIUM PLUS to gain a deeper understanding of the local market dynamics and build stronger relationships with South African businesses.",
  },
  {
    year: "2023",
    title:
      "2023: IRIDIUM PLUS celebrates its 10th anniversary, solidifying its position as a leading Immigration Consultancy and international trade firm",
    description:
      "The company continues to expand its operations and services, while maintaining its commitment to providing exceptional value to its clients. In 2023, IRIDIUM PLUS launched a new initiative to promote sustainable trade practices and support local communities. The company also expanded its team of experienced professionals to meet the growing demand for its services.",
  },
];

function IridiumPlusHistory() {
  return (
    <main className="flex flex-col px-5 py-24 mt-3.5 bg-white">
      <div
        data-aos="zoom-in"
        data-aos-duration="3000"
        className="flex flex-col items-start text-start w-full max-w-4xl mx-auto"
      >
        <div className="text-start md:-ml-24 lg:-ml-48 px-4 md:px-0">
        <h1
          style={{ fontFamily: 'roboto, sans-serif' }}
          className="text-[56.38px] md:text-[56.38px] leading-tight font-semibold tracking-tighter text-zinc-900"
        >
          IRIDIUM PLUS: A History of <br /> Growth and Innovation
        </h1>
        <p className="mt-4 text-base md:text-[18.79px] font-light tracking-tighter text-black">
          IRIDIUM PLUS was founded in 2012 with a vision to become a leading
          international <br /> trade company based in Zambia. Since its inception, the
          company has experienced <br /> rapid growth and expansion, establishing itself
          as a trusted partner for businesses <br /> seeking to navigate the complexities
          of the global market.
        </p>
        </div>
      </div>
      <div className="mt-12">
        <section
          data-aos="fade-left"
          data-aos-duration="2500"
          className="flex overflow-hidden flex-wrap gap-3 justify-center items-center mx-auto max-w-[1247px] min-h-[455px] max-md:mt-10"
        >
          {timelineData.map((item, index) => (
            <TimelineCard
              key={index}
              year={item.year}
              title={item.title}
              description={item.description}
            />
          ))}
        </section>
      </div>
    </main>
  );
}

export default IridiumPlusHistory;

import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Hero from "./components/Hero";
import ScheduleMeeting from "./components/ScheduleMeeting/ScheduleMeeting";
import WhyIridiumPlus from "./components/WhyIridiumPlus/WhyIridiumPlus";
import DeliveringServices from "./components/DeliveryServices/DeliveryServices";
import Testimonials from "./components/Testimonials";
import ContactSection from "./components/ContactSection/ContactSection";
import Footer from "./components/Footer";
import IridiumPlusHistory from "./components/IridiumPlusHistory/IridiumPlusHistory";
import ServicePage from "./pages/ServicePage"; 
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
import 'typeface-roboto';
import 'typeface-inter';

// Helper component to conditionally render landing page sections
const Home = () => {
  // Get the current path
  const location = useLocation(); 
  
  // Only render these components on the homepage ("/")
  if (location.pathname === '/') {
    return (
      <>
        <Hero />
        <WhyIridiumPlus />
        <DeliveringServices />
        <IridiumPlusHistory />
        <Testimonials />
        <ScheduleMeeting />  
        <ContactSection />
      </>
    );
  }
  // Don't render anything for other routes
  return null; 
};

const App = () => {
  return (
    <Router>
      <div className="flex overflow-hidden flex-col items-center bg-white">
        <Header /> {/* Always shown */}
        
        <Routes>{
        /* Home page route */}
          <Route path="/" element={<Home />} /> 
          <Route path="/services" element={<ServicePage />} />
          <Route path="/about" element={<AboutUsPage />} />
          <Route path="/contactus" element={<ContactPage />} />
          {/* <Route path="/pages/Permit-visa" element={<Permit-visa />} /> */}
          {/* Add more routes as necessary */}
        </Routes>
        {/* Always shown */}
        <Footer /> 
      </div>
    </Router>
  );
};

export default App;

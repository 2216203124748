import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import iridiumpLogo from '../assets/icons/iridiumplus.svg';
import contactIcon from '../assets/icons/contact.svg';

const Header = () => {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  // Update the active link when the component mounts or when the route changes
  React.useEffect(() => {
    setActiveLink(location.pathname);
  }, [location]);

  return (
    <header className="flex flex-col justify-center items-center self-stretch px-16 py-3.5 w-full text-[17.6px] not-italic leading-5 font-semibold text-black bg-white max-md:px-5 max-md:max-w-full">
      <nav className="flex flex-wrap gap-1 items-start max-w-full w-[1048px]">
        {/* Logo and Text Section */}
        <div className="flex items-center">
          <Link to="/" className="flex flex-col items-center mx-auto">
            <img
              src={iridiumpLogo}
              alt="logo"
              className="w-8 h-auto object-contain"
            />
            <div className="grow self-end text-sm tracking-[2.25px]">
              <span className="text-teal-500">IRIDIUM</span>{" "}
              <span className="text-indigo-950">PLUS</span>{" "}
            </div>
          </Link>
        </div>

        <div className="flex text-center ml-1 mt-2">
          <Link
            to="/"
            className={`p-4 px-8 max-md:px-5 ${
              activeLink === "/" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/")}
          >
            Home
          </Link>

          <Link
            to="/about"
            className={`p-4 px-8 max-md:px-5 ${
              activeLink === "/about" 
                ? "text-teal-500 " 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/about")}
          >
            About Us
          </Link>

          <Link
            to="/services"
            className={`p-4 px-8 ${
              activeLink === "/services" 
                ? "text-teal-500 " 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/services")}
          >
            Services
          </Link>

          <Link
            to="/contactus"
            className={`p-4 px-8  ${
              activeLink === "/contactus" 
                ? "text-teal-500" 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/contactus")}
          >
            Contact
          </Link>

          <Link
            to="/visa-permit"
            className={`p-4 px-8 ${
              activeLink === "/visa-permit" 
                ? "text-teal-500 " 
                : "hover:text-teal-500 hover:border-teal-500 border-transparent"
            }`}
            onClick={() => setActiveLink("/visa-permit")}
          >
            Visa/Permit
          </Link>

          {/* Contact Section */}
          <div className="flex items-center gap-2.5 text-base font-medium text-center">
            <img
              loading="lazy"
              src={contactIcon}
              alt="Contact"
              className="object-contain shrink-0 w-8 aspect-square"
            />
            <div>
              Any Questions? Call Us <br />+ 260 979 72 001 7
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
